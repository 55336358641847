<template>
  <div class="ImgGallery flexL">
    <a-space :size="12" class="ImgGallery-space">
      <template v-for="(item, index) in urls.slice(0, showImgNum)" :key="index">
        <a-popover placement="rightTop">
          <template #content>
            <img class="ImgGallery-popover-img" :style="popoverImgStyle" :src="formatImgFn(item)" />
          </template>
          <img class="ImgGallery-img" :style="imgStyle" :src="formatImgFn(item)" />
        </a-popover>
      </template>
      <span class="ImgGallery-img-more" :style="imgStyle" v-if="showMore && urls.length > showImgNum" @click="more">More</span>
    </a-space>
  </div>
</template>

<script>
import { computed } from 'vue';
import { formatImg } from '@/utils/util.js';
export default {
  props: {
    urls: {
      type: Array,
      default() {
        return [
          'https://video.wahool.com/goods/2022-08-31/2610ac9572d659ada87f4e333f691eef-3471f9c9214dd00cad122c4222d95e73.png?x-oss-process=image/resize,m_lfit,h_{height},w_{width},color_FFFFFF,image/format,jpg',
          'https://video.wahool.com/goods/2022-08-31/2610ac9572d659ada87f4e333f691eef-3471f9c9214dd00cad122c4222d95e73.png?x-oss-process=image/resize,m_lfit,h_{height},w_{width},color_FFFFFF,image/format,jpg',
          'https://video.wahool.com/goods/2022-08-31/2610ac9572d659ada87f4e333f691eef-3471f9c9214dd00cad122c4222d95e73.png?x-oss-process=image/resize,m_lfit,h_{height},w_{width},color_FFFFFF,image/format,jpg',
          'https://video.wahool.com/goods/2022-08-31/2610ac9572d659ada87f4e333f691eef-3471f9c9214dd00cad122c4222d95e73.png?x-oss-process=image/resize,m_lfit,h_{height},w_{width},color_FFFFFF,image/format,jpg',
          'https://video.wahool.com/goods/2022-08-31/2610ac9572d659ada87f4e333f691eef-3471f9c9214dd00cad122c4222d95e73.png?x-oss-process=image/resize,m_lfit,h_{height},w_{width},color_FFFFFF,image/format,jpg',
          'https://video.wahool.com/goods/2022-08-31/2610ac9572d659ada87f4e333f691eef-3471f9c9214dd00cad122c4222d95e73.png?x-oss-process=image/resize,m_lfit,h_{height},w_{width},color_FFFFFF,image/format,jpg',
        ];
      },
    },
    showImgNum: {
      type: Number,
      default: 5,
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    popoverImgWidth: {
      type: Number,
      default: 300,
    },
    imgWidth: {
      type: Number,
      default: 36,
    },
    imgHeight: {
      type: Number,
      default: 46,
    },
  },
  emits: ['viewMore'],
  setup(props, { emit }) {
    const imgStyle = computed(() => {
      return {
        width: `${props.imgWidth}px`,
        height: `${props.imgHeight}px`,
        'line-height': `${props.imgHeight}px`,
      };
    });
    const popoverImgStyle = computed(() => {
      return {
        width: `${props.popoverImgWidth}px`,
        // height: `${props.popoverImgHeight}px`,
      };
    });
    const formatImgFn = (path, w = 500, h = 500, bw = 500, by = 500) => formatImg(path, w, h, bw, by);
    const more = () => emit('viewMore');
    return {
      imgStyle,
      popoverImgStyle,
      formatImgFn,
      more,
    };
  },
};
</script>

<style lang="less">
.ImgGallery {
  // .ImgGallery-popover-img {
  //   width: 300px;
  // }
  .ImgGallery-img,
  .ImgGallery-img-more {
    display: inline-block;
    // width: 36px;
    // height: 46px;
    // line-height: 46px;
    border-radius: 4px;
    color: rgba(93, 87, 88, 0.5);
    font-size: 12px;
    background-color: #f7f7f7;
    text-align: center;
  }
  .ImgGallery-img-more {
    cursor: pointer;
  }
  .ant-space-item {
    &:last-child {
      flex-shrink: 0;
    }
  }
}
</style>
